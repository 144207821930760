<template>
  <div>
    <hero/>
    <contents/>
  </div>
</template>

<script>

export default {
  components: {
    Hero: () => import ('@/components/training/Hero'),
    Contents: () => import('@/components/training/Contents')
  },
  mounted(){
    this.$store.dispatch('app/setLink', 5)
  }
}
</script>

<style scoped>
@import "../../node_modules/leaflet/dist/leaflet.css";
</style>
